import React from "react";
import { Phone, MapPin, Clock8, Calendar } from 'lucide-react';

export const ClinicNap = () => {
  return (
    // <div className="md:px-4 py-1 border-b border-gray-200 bg-red-600 sticky top-0 z-50 flex justify-end ">
    <div className="md:px-4 pb-1 border-b border-gray-200 bg-red-600 sticky top-0 z-50 flex justify-end md:justify-between">
      {/* <div className="flex flex-row my-0 px-0 justify-end md:justify-center"> */}
        <div className="flex flex-col">
          
          <span className="px-2 pb-2 md:pt-2 mx-2">
            
{/*              
              <span><Calendar color="red" size="12" className="inline"/></span><span className="text-white font-bold text-xs mr-2">{" "}{" "}Mon-Fri</span>
              <span><Clock8 color="red" size="12" className="inline"/></span><span className="text-white font-bold text-xs mr-2 ">{" "}{" "}8am-5pm</span> */}
              {/* <br className="md:hidden"/> */}
              <a className="text-xs font-bold overscroll-contain mr-2" href="/contact"> 
              <br className="lg:hidden"/>
              <span><MapPin size={12} color="white" className="inline"/></span><span className="text-white">{" "}Directions</span>
              </a>
              <a className=" text-white text-xs font-bold" href="tel:281-955-9158">
              <span><Phone size={12} color="white" className="inline"/></span><span className="text-white">{" "}(281) 955-9158</span>
              </a>
            
              
            
          </span>
        </div>
        {/* <div className="flex flex-col">
          <span className="p-2 mx-2">
              <a className="hidden md:hidden lg:block text-white text-xs font-bold" href="tel:281-955-9158">
              <span><Phone size={12} color="red" className="inline"/></span><span className="text-white">{" "}(281) 955-9158</span>
              </a>
              <a className="lg:hidden text-gray-500 text-xs font-bold" href="tel:281-955-9158">
              <span><Phone size={12} fill="red" className="inline"/></span><span className="text-white">{" "}Call</span>
              </a>
          </span>
        </div> */}
      {/* </div> */}
    </div>
  )
}