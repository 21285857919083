
import { useState, useEffect } from 'react';
// import LogoWithText from './LogoWithText';
import Image from "next/image";
import { Navigation } from "./Navigation";

export default function MainNavigation(): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = (): void => setIsMenuOpen(!isMenuOpen);
  useEffect(() => { 
  }, []);

  return (
    <section className="my-10 px-6">
      <div className='flex items-center justify-between px-8 py-3.5 container mx-auto bg-white rounded-full'>
        <div className='w-auto'>
          <div className='flex flex-wrap items-center'>
            <div className='w-auto'>
              <a href='/'>
              < Image src="/brand/cypress-cardiovascular-logo-lg.png" alt="Cypress Cardiovascular Institute Logo" width={100} height={75} />
              </a>
            </div>
          </div>
        </div>
        <div className='hidden md:block'><Navigation /></div>
        <div className='w-auto'>
          <div className='flex flex-wrap items-center'>
            <div className='w-auto hidden lg:block'>
              <div className='flex flex-wrap -m-2'>
                <div className='w-full md:w-auto p-2'>
                <a className="group hidden md:inline-flex py-4 px-8 items-center justify-center leading-none font-medium text-black hover:text-white border border-black rounded-full hover:bg-blue-800 transition duration-200" href="/contact">
          <span className="mr-2">
            Contact
          </span>
          <span className="group-hover:rotate-45 transform transition duration-100">
            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/   2000/svg">
            <path d="M9 1L1 9" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 8.33571V1H1.66429" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </span>
        </a>
                </div>
              </div>
            </div>
            <div className='w-auto lg:hidden'>
              <button onClick={toggleMenu}>
                <svg
                  className='navbar-burger text-blue-800'
                  width={45}
                  height={45}
                  viewBox='0 0 56 56'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    width={56}
                    height={56}
                    rx={28}
                    fill='currentColor'
                  />
                  <path
                    d='M37 32H19M37 24H19'
                    stroke='white'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className='navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
          <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' onClick={toggleMenu}></div>
          <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
            <div className='flex flex-wrap justify-between h-full'>
              <div className='w-full'>
                <div className='flex items-center justify-between -m-2'>
                  <div className='w-auto p-2'>
                    <a className='inline-block' href='/'>
                    < Image src="/brand/cypress-cardiovascular-logo-lg.png" alt="Cypress Cardiovascular Institute Logo" width={100} height={75} />
                    </a>
                  </div>
                  <div className='w-auto p-2'>
                    <a className='inline-block navbar-burger' href='#' onClick={toggleMenu}>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 18L18 6M6 6L18 18'
                          stroke='#111827'
                          strokeWidth={2}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className='flex flex-col justify-center py-8 w-full'>
                <ul>
                  <li className='mb-9'>
                    <a
                      className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                      href='/our-services' onClick={() => setIsMenuOpen(false)}
                    >
                      Our Services
                    </a>
                  </li>
                  <li className='mb-9'>
                    <a
                      className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                      href='/vein-care' onClick={() => setIsMenuOpen(false)}
                    >
                      Vein Care
                    </a>
                  </li>
                  <li className='mb-9'>
                    <a
                      className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                      href='/heart-care'
                    >
                     Heart Care
                    </a>
                  </li>
                  <li className='mb-9'>
                    <a
                      className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                      href='/vascular-care'
                    >
                      Vascular Care
                    </a>
                  </li>
                  <li className='mb-9'>
                    <a
                      className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                      href='/contact'
                    >
                      Our Location
                    </a>
                  </li>
                  <li>
                    <a
                      className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                      href='/our-team'
                      >
                      Our Team
                    </a>
                  </li>
                </ul>
              </div>
              <div className='flex flex-col justify-end w-full pb-8'>
                <div className='flex flex-wrap -m-2'>
                  <div className='w-full p-2'>
                    {/* <a
                      className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                      href='/new-patient'
                    >
                      New Patients
                    </a> */}
                  </div>
                  <div className='w-full p-2'>
                    <a
                      className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:ring-blue-200 rounded-full'
                      href='/contact'
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )}
    </section>
  );
};
