import Image from "next/image";
import React from "react";

const LogoMain = ({ className }: any) => {
  return (
   < Image src="/brand/cypress-cardiovascular-logo-lg.png" alt="Cypress Cardiovascular Institute Logo" width={100} height={75} />
  );
};

export default LogoMain;
