import clsx from "clsx";
import LogoMain from "icons/logo";
import Link from "next/link";
import React from "react";

const LogoWithText = ({
  logoClassName,
}: {
  textClassName?: string;
  logoClassName?: string;
}) => {
  return (
    <div className="flex flex-row items-center space-x-1">
      <Link href="/">
        <a className="">
        <LogoMain className={clsx(logoClassName)} />
      </a>
      </Link>
    </div>
  );
};

export default LogoWithText;
