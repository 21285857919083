"use client"

import * as React from "react"
import Link from "next/link"
import { cn } from "utils/cn"
import { HeartPulse } from 'lucide-react'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"

const care: { title: string; href: string; description: string }[] = [
  {
    title: "Our Services",
    href: "/our-services",
    description: " Advanced treatments for various vein conditions such as Veneseal, Sclerotherapy, ELT, RFA, & Vein Ablation."
  },
  {
    title: "Vein Care",
    href: "/vein-care",
    description: " Advanced treatments for various vein conditions such as Veneseal, Sclerotherapy, ELT, RFA, & Vein Ablation."
  },
  {
    title: "Vascular Care",
    href: "/vascular-care",
    description: "Vein treatments are essential for managing symptoms, preventing complications, and improving quality of life in individuals affected by vein diseases"
  },
  {
    title: "Heart Care",
    href: "/heart-care",
    description: "Any health issues or diseases that affect the blood vessels supplying the heart muscle, which may lead to chest pain, heart attacks, or other cardiovascular complications"
  },
]

const care101: { title: string; href: string; description: string }[] = [
  {
    title: "Vein Care 101",
    href: "/vein-care-101",
    description: " Advanced treatments for various vein conditions such as Veneseal, Sclerotherapy, ELT, RFA, & Vein Ablation."
  },
  {
    title: "Vascular Care 101",
    href: "/vascular-care-101",
    description: "Vein treatments are essential for managing symptoms, preventing complications, and improving quality of life in individuals affected by vein diseases"
  },
  {
    title: "Heart Care 101",
    href: "/heart-care-101",
    description: "Any health issues or diseases that affect the blood vessels supplying the heart muscle, which may lead to chest pain, heart attacks, or other cardiovascular complications"
  },
]

export function Navigation() {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger>About</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink asChild>
                  <a
                    className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md hover:text-white hover:bg-blue-800"
                    href="/about-us"
                  >
                    <HeartPulse size={60} fill="red" color="white" /> 
                    <div className="mb-2 mt-4 text-lg font-medium">
                      Cypress Cardiovascular Institute
                    </div>
                    <p className="text-sm leading-tight text-muted-foreground">
                      Advanced treatments. Compassionate care. Your heart is in good hands.
                    </p>
                  </a>
                </NavigationMenuLink>
              </li>
              <ListItem href="/about-us" title="Introduction" className="hover:text-white hover:bg-blue-800">
                Get to know our practice and our mission.
              </ListItem>
              <ListItem href="/our-team" title="Our Team" className="hover:text-white hover:bg-blue-800">
                See our cardiologists and vascular surgeons.
              </ListItem>
              <ListItem href="/contact" title="Convenient Location" className="hover:text-white hover:bg-red-600">
                Convenient location and surrounding area. Just outside of Houston in Cypress, TX.
              </ListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Services</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-1 lg:w-[400px]">
              {care.map((care) => (
                <ListItem
                  key={care.title}
                  title={care.title}
                  href={care.href}
                  className={
                    care.title === "Vein Care"
                      ? "hover:bg-blue-800"
                      : care.title === "Heart Care"
                      ? "hover:bg-red-600"
                      : care.title === "Vascular Care"
                      ? "hover:bg-purple-800"
                      : "hover:bg-blue-800"
                  }
                >
                  {/* {care.description} */}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Learn</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-1 lg:w-[400px]">
              {care101.map((care101) => (
                <ListItem
                  key={care101.title}
                  title={care101.title}
                  href={care101.href}
                  className={
                    care101.title === "Vein Care 101"
                      ? "hover:bg-blue-800"
                      : care101.title === "Heart Care 101"
                      ? "hover:bg-red-600"
                      : care101.title === "Vascular Care 101"
                      ? "hover:bg-purple-800"
                      : "hover:bg-blue-800"
                  }
                >
                  {/* {care.description} */}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/blog" legacyBehavior passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Blog
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:text-white focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-lg font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"